import {
  IconApiApp,
  IconArrowBarToUp,
  IconArrowLeft,
  IconArrowUp,
  IconArrowsSort,
  IconArticle,
  IconBell,
  IconBookmark,
  IconBrandInstagram,
  IconBrandYoutube,
  IconBulb,
  IconCalendar,
  IconCalendarEvent,
  IconCertificate,
  IconChevronRight,
  IconClock,
  IconCloudCode,
  IconCode,
  IconCornerDownRight,
  IconCurrencyDollar,
  IconDownload,
  IconEdit,
  IconEye,
  IconFileCheck,
  IconFileImport,
  IconFileMinus,
  IconFiles,
  IconFriends,
  IconHeadphones,
  IconHome,
  IconInfoCircle,
  IconLink,
  IconList,
  IconLock,
  IconLogout,
  IconMenu2,
  IconMessageChatbot,
  IconMessageCircle2,
  IconNotes,
  IconNotification,
  IconPackages,
  IconPalette,
  IconPencil,
  IconPhoto,
  IconPlus,
  IconQuestionMark,
  IconRobot,
  IconSearch,
  IconSelect,
  IconSettings,
  IconShare,
  IconSignature,
  IconThumbUp,
  IconTrash,
  IconUrgent,
  IconUsers,
  IconWorld,
  IconWorldDownload,
  IconX,
} from "@tabler/icons-react";

import { AclType } from "types/types";

export const ACL_SETTINGS: AclType[] = [
  {
    label: "공개",
    value: "1",
    icon: IconWorld,
  },
  {
    label: "그룹에만 공개",
    value: "2",
    icon: IconFriends,
  },
  {
    label: "비공개",
    value: "3",
    icon: IconLock,
  },
];

export const PLAN = [
  {
    label: "Free",
    value: 1,
  },
  {
    label: "Prop",
    value: 2,
  },
  {
    label: "Premium",
    value: 3,
  },
  {
    label: "disable",
    value: 4,
  },
];

export const UID_OBJECTS = [
  {
    value: "internal",
    label: "내부콘텐츠",
    href: "/internals",
    length: 10,
    color: "blue",
  },
  {
    value: "content",
    label: "외부콘텐츠",
    href: "/links",
    length: 12,
    color: "orange",
  },
  {
    value: "scrap",
    label: "스크랩",
    href: "/scraps",
    length: 9,
    color: "teal",
  },
  {
    value: "collection",
    label: "컬렉션",
    href: "/collections",
    length: 8,
    color: "violet",
  },
];

export const ICONS = {
  link: IconLink,
  collection: IconPackages,
  scrap: IconBookmark,
  notebook: IconCloudCode,
  howto: IconQuestionMark,
  pricing: IconCurrencyDollar,
  customer: IconHeadphones,
  group: IconUsers,
  comment: IconMessageCircle2,
  aicomment: IconMessageChatbot,
  logout: IconLogout,
  view: IconEye,
  contentList: IconFiles,
  time: IconClock,
  information: IconInfoCircle,
  sort: IconArrowsSort,
  search: IconSearch,
  share: IconShare,
  report: IconUrgent,
  reply: IconCornerDownRight,
  author: IconSignature,
  edit: IconEdit,
  delete: IconTrash,
  enter: IconArrowUp,
  isAi: IconRobot,
  add: IconPlus,
  back: IconArrowLeft,
  more: IconChevronRight,
  profile: IconSettings,
  instagram: IconBrandInstagram,
  youtube: IconBrandYoutube,
  menu: IconMenu2,
  notification: IconBell,
  home: IconHome,
  calendar: IconCalendar,
  excludingContent: IconFileMinus,
  internal: IconArticle,
  channel: IconList,
  app: IconApiApp,
  setting: IconSettings,
  course: IconCertificate,
  write: IconPencil,
  close: IconX,
  selector: IconSelect,
  like: IconThumbUp,
  list: IconList,
  scroll2top: IconArrowBarToUp,
  file: IconFileCheck,
  art: IconPalette,
  download: IconDownload,
  gptDocs: IconFileImport,
  designGPT: IconPhoto,
  GPTCoder: IconCode,
  free: IconNotes,
  qna: IconQuestionMark,
  news: IconWorldDownload,
  tip: IconBulb,
  event: IconCalendarEvent,
  notice: IconNotification,
};

export const BOARDS = {
  free: "community_free",
  qna: "community_qna",
  news: "community_news",
  tip: "community_tip",
  notice: "community_notice",
  pr: "community_pr",
  test: "community_test",
};

export const APPS = {
  chatgpt: "chatgpt",
  gptdocs: "chatgpt/gptdocs",
  gptcoder: "chatgpt/gptcoder",
  designgpt: "chatgpt/designgpt",
  "image-gen": "image-gen",
  notebook: "notebook",
  cloud: "cloud",
};

export const InternalContentTypes = {
  // Community
  ...BOARDS,
  // Apps
  ...APPS,

  // Others
  course: "course",
  market: "market",
};
