import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

import { MOBILE_MENUS } from "containers/layout/Mobile/MobileBottom";

export const atomGroupIndex = atomWithStorage("groupIndex", 0);
export const atomGroupData = atomWithStorage("groupData", {
  uid: "COREDOTTODAY",
  title: "Core.Today",
  detail: "코어닷투데이",
});
export const atomPreviousPath = atom("/");
export const atomDrawerMenu = atom(false);
export const atomDrawerMenuLms = atom(false);
export const atomMobileBottomActiveTab =
  atom<(typeof MOBILE_MENUS)[number]["value"]>("/");
export const atomLexicalClear = atom(undefined);
