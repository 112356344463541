import Link from "next/link";

import {
  Box,
  Button,
  Center,
  CloseButton,
  Divider,
  Group,
  Popover,
  SimpleGrid,
  Text,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";

import { useHeaderStyles } from "containers/layout/Header";

export interface HeaderBoardProps {
  links: JSX.Element;
  isOpen?: boolean;
  handleOpen?: () => void;
  handleClose?: () => void;
}
export default function HeaderBoards({
  links,
  isOpen,
  handleOpen,
  handleClose,
}: HeaderBoardProps) {
  const { classes, theme } = useHeaderStyles();

  const handleOpenWithDelay: React.MouseEventHandler<HTMLDivElement> = (
    event
  ) => {
    let timeoutId: NodeJS.Timeout;
    let element = event.currentTarget;

    element.addEventListener("mouseenter", () => {
      timeoutId = setTimeout(() => {
        handleOpen && handleOpen();
      }, 100);
    });

    element.addEventListener("mouseleave", () => {
      clearTimeout(timeoutId);
    });
  };

  return (
    <div onMouseLeave={handleClose} className="h-full">
      <Popover
        width={600}
        position="bottom"
        radius="md"
        shadow="md"
        // withinPortal
        opened={isOpen}
        offset={0}
      >
        <Popover.Target>
          <div
            className={classes.link + " cursor-pointer"}
            onMouseEnter={handleOpenWithDelay}
            onClick={handleOpen}
          >
            <Center inline>
              <Box component="span" mr={5}>
                공개 커뮤니티
              </Box>
              <IconChevronDown
                size={16}
                color={theme.fn.primaryColor()}
                className={`transition ${isOpen && "rotate-180"}`}
              />
            </Center>
          </div>
        </Popover.Target>

        <Popover.Dropdown sx={{ overflow: "hidden" }}>
          <Group position="apart" px="md">
            <div className="flex items-end gap-4">
              <Text fw={500}>커뮤니티</Text>
              <Link
                href="/community"
                className="mb-0.5 text-[12px] text-xs text-[#1C7ED6] hover:underline"
                onClick={handleClose}
              >
                전체보기
              </Link>
            </div>
            <CloseButton onClick={handleClose} />
          </Group>
          <Divider
            my="sm"
            mx="-md"
            color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
          />
          <SimpleGrid cols={2} spacing={0}>
            {links}
          </SimpleGrid>
          <div className={classes.dropdownFooter}>
            <Group position="apart">
              <div>
                <Text fw={500} fz="sm">
                  인공지능은 필수 교양!
                </Text>
                <Text size="xs" color="dimmed">
                  인공지능의 즐거운 소통과 정보 공유 천국을 만끽하세요!🌈
                </Text>
              </div>
              {/* <Button variant="default">Get started</Button> */}
            </Group>
          </div>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
}
