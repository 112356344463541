import { forwardRef } from "react";

import {
  ButtonProps,
  ActionIcon as MantineActionIcon,
  ActionIconProps as MantineActionIconProps,
  Tooltip,
} from "@mantine/core";

import { CDTButtonProps } from "types/components";

import { userAnalysis } from "@client/userAnalysis";

export interface ActionIconProps
  extends MantineActionIconProps,
    CDTButtonProps,
    Omit<ButtonProps, "classNames" | "size" | "styles" | "variant"> {}

const ActionIcon = forwardRef<HTMLButtonElement, ActionIconProps>(
  (
    {
      children,
      onClick,
      eventName,
      eventProps,
      tooltip,
      tooltipProps,
      ...props
    }: ActionIconProps,
    ref
  ) => {
    const handleClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      userAnalysis(eventName, "BtnClick");
      onClick && onClick(event);
    };

    return (
      <Tooltip label={tooltip} hidden={!tooltip}>
        <MantineActionIcon ref={ref} onClick={handleClick} {...props}>
          {children}
        </MantineActionIcon>
      </Tooltip>
    );
  }
);

ActionIcon.displayName = "ActionIcon";

export default ActionIcon;
