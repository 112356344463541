import {
  Icon,
  IconBook,
  IconChartPie3,
  IconCloud,
  IconCode,
  IconCoin,
  IconFingerprint,
  IconNotification,
} from "@tabler/icons-react";

import { BOARDS, ICONS } from "constants/settings";

export type BoardMenuType = {
  icon: Icon;
  title: string;
  description: string;
  value: keyof typeof BOARDS;
  href: string;
};

export type BoardType = keyof typeof BOARDS;
const COMMUNITY_URL = "/community";
export const BOARD_MENUS: BoardMenuType[] = [
  {
    icon: ICONS.free,
    title: "자유게시판",
    description: "무엇이든 자유롭게 이야기해요.",
    value: "free",
    href: `${COMMUNITY_URL}/free`,
  },
  {
    icon: ICONS.qna,
    title: "질문게시판",
    description: "궁금한 것을 묻고 답하면서 함께 성장해요. AI가 도와줄게요.",
    value: "qna",
    href: `${COMMUNITY_URL}/qna`,
  },
  {
    icon: ICONS.news,
    title: "새소식",
    description: "AI 관련 중요한 새소식을 모아봤어요.",
    value: "news",
    href: `${COMMUNITY_URL}/news`,
  },
  {
    icon: ICONS.tip,
    title: "꿀팁 게시판",
    description: "함께 공유하면 좋을 꿀팁을 나누어봐요.",
    value: "tip",
    href: `${COMMUNITY_URL}/tip`,
  },
  {
    icon: ICONS.event,
    title: "홍보게시판",
    description:
      "AI 관련 행사, 이벤트, 구인, 제품, 프로젝트 등을 홍보해주세요.",
    value: "pr",
    href: `${COMMUNITY_URL}/pr`,
  },
  {
    icon: ICONS.notice,
    title: "공지사항",
    description: "코어닷투데이이의 공지사항을 확인하실 수 있어요.",
    value: "notice",
    href: `${COMMUNITY_URL}/notice`,
  },
];

export const APPS = [
  {
    icon: ICONS.notebook,
    title: "가상노트북",
    description:
      "클라우드 환경에서 동료와 함께 실시간으로 노트북을 사용할 수 있어요.",
    href: "/apps/notebook",
  },
  // {
  //   icon: IconCoin,
  //   title: "이미지 생성",
  //   description: "텍스트를 입력하면 이미지를 생성해줘요.",
  //   href: "/apps/core2art",
  // },
  {
    icon: ICONS.link,
    title: "외부콘텐츠",
    description: "외부에 있는 좋은 글들이 모여있고, AI가 댓글을 달아줘요.",
    href: "/links",
  },
  {
    icon: ICONS.collection,
    title: "컬렉션",
    description: "외부콘텐츠들의 모음집이예요.",
    href: "/collections",
  },
  {
    icon: ICONS.scrap,
    title: "스크랩폴더",
    description: "다시 보고싶은 외부콘텐츠들을 모아둘 수 있어요.",
    href: "/scraps",
    needLogin: true,
  },
  // {
  //   icon: IconCloud,
  //   title: "클라우드",
  //   description: "Combusken battles with the intensely hot flames it spews",
  //   href: "/apps/cloud",
  // },
];
