import { Button, Modal, TextInput, Textarea } from "@mantine/core";
import { useForm } from "react-hook-form";

import { VALIDATION } from "constants/constants";
import { GroupItem } from "types/schema";
import { ModalProps } from "types/types";

import { usePostGroup } from "@server/engineGroup";

type FormData = Pick<GroupItem, "title" | "detail">;

export interface AddGroupModalProps extends ModalProps {}
export default function AddGroupModal({
  opened,
  onClose,
  ...props
}: AddGroupModalProps) {
  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormData>();

  const mutation = usePostGroup();

  const onValid = (data: FormData) => {
    mutation.mutate(
      {
        body: data,
      },
      {
        onSettled: () => {
          handleClose();
        },
      }
    );
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Modal opened={opened} onClose={handleClose} {...props} title="그룹 생성">
      <form onSubmit={handleSubmit(onValid)} className="flex flex-col gap-4">
        <TextInput
          label="그룹 이름"
          placeholder="그룹 이름을 입력해주세요"
          required
          data-autofocus
          title="최대 28글자까지 입력 가능해요"
          maxLength={VALIDATION.group.title.maxLength}
          description={`${watch("title")?.length ?? "0"} / ${
            VALIDATION.group.title.maxLength
          }`}
          error={errors.title?.message}
          {...register("title", {
            maxLength: VALIDATION.group.title.maxLength,
            required: true,
          })}
          disabled={mutation.isLoading}
        />
        <Textarea
          label="그룹 설명"
          placeholder="그룹에 대한 설명을 입력해주세요"
          title="최대 32글자까지 입력 가능해요"
          maxLength={VALIDATION.group.detail.maxLength}
          description={`${watch("detail")?.length ?? "0"} / ${
            VALIDATION.group.detail.maxLength
          }`}
          error={errors.detail?.message}
          {...register("detail", {
            maxLength: VALIDATION.group.detail.maxLength,
          })}
          disabled={mutation.isLoading}
        />
        <Button type="submit" loading={mutation.isLoading}>
          그룹 생성
        </Button>
      </form>
    </Modal>
  );
}
