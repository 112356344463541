import { captureException } from "@sentry/nextjs";
import { QueryClient, QueryClientConfig } from "@tanstack/react-query";

import { userAnalysis } from "@client/userAnalysis";

const queryErrorHandler = (error: any) => {
  if (
    error?.response?.status === 500 &&
    error?.response?.data?.detail === "Token is expired"
  ) {
    console.error(error);
  }
  userAnalysis("Error: ReactQuery", "Error-ReactQuery", {
    error,
    "Error Response": error?.response,
  });
  captureException(error);
};

export const queryClientConfigs: QueryClientConfig = {
  defaultOptions: {
    queries: {
      retry: 1,
      onError: queryErrorHandler,
      suspense: false,
      useErrorBoundary: true,
      // refetchOnMount: false,
      // refetchOnReconnect: false,
      // refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
    },
    mutations: {
      useErrorBoundary: (error: any) => error?.response?.status >= 500,
      onError(error, variables, context) {
        console.error(
          "MutationError",
          "error:",
          error,
          "variables",
          variables,
          "context",
          context
        );
      },
    },
  },
};

export const queryClient = new QueryClient(queryClientConfigs);
