import { MenuType } from "types/types";

interface MenuLabelWithIcon {
  menu: MenuType;
}

export default function MenuLabelWithIcon({ menu }: MenuLabelWithIcon) {
  return (
    <div className="flex h-full w-full items-center space-x-1">
      {menu?.icon && <menu.icon size={20} stroke={2} />}
      <span>{menu.label}</span>
    </div>
  );
}
