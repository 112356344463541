import dynamic from "next/dynamic";

import { openModal } from "@mantine/modals";

const NeedLoginModal = dynamic(
  () => import("containers/modals/NeedLoginModal")
);

export const openModals = {
  needLogin: ({
    title = "코어닷투데이 멤버가 되어보세요!",
    text,
  }: {
    title?: string;
    text?: string;
  }) =>
    openModal({
      title: `코어닷투데이 멤버가 되어보세요!`,
      children: <NeedLoginModal text={text} />,
      style: {
        width: "400px",
      },
    }),
};
