import { ErrorProps } from "next/error";
import Image from "next/image";
import { useRouter } from "next/router";

import Button from "components/common/button/Button";

export interface ErrorPageProps {
  text?: React.ReactNode;
  withRefresh?: boolean;
  error?: ErrorProps;
}

export default function ErrorPage({
  text = (
    <>
      오류가 발생했어요.
      <br />
      잠시 후에 다시 시도 해주세요.
    </>
  ),
  withRefresh = false,
  error,
}: ErrorPageProps) {
  const router = useRouter();
  console.log("ERROR", error);

  return (
    <div className="m-auto mt-32 flex flex-col items-center justify-center gap-5 text-center">
      <Image
        src="/images/character/sorry.png"
        alt="sorry"
        width="151"
        height="182"
      />
      <span>{text}</span>
      <div className="flex gap-2">
        <Button
          eventName="ErrorPage: 이전 페이지로 이동"
          variant="outline"
          onClick={async () => {
            router.back();
            setTimeout(() => {
              router.reload();
            }, 100);
          }}
        >
          이전 페이지로 이동
        </Button>
        {withRefresh ? (
          <Button
            eventName="ErrorPage: 새로고침"
            onClick={() => {
              router.reload();
            }}
          >
            새로고침
          </Button>
        ) : (
          <Button
            eventName="ErrorPage: 코어닷투데이로 이동"
            onClick={() => {
              router.push(`/`);
              setTimeout(() => {
                router.reload();
              }, 100);
            }}
          >
            메인 페이지로 이동
          </Button>
        )}
      </div>
    </div>
  );
}
