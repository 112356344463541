import { useSession } from "next-auth/react";
import Link from "next/link";
import { useRouter } from "next/router";

import { Button, Group, Text, createStyles, rem } from "@mantine/core";

import { ICONS } from "constants/settings";
import { GroupSelector } from "containers/groups/layout/GroupSelector";

import { useGetGroupByUid, useGetGroupListByUid } from "@server/engineGroup";

const useStyles = createStyles((theme) => ({
  section: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    // marginRight: `calc(${theme.spacing.md} * -1)`,
    marginBottom: theme.spacing.md,

    "&:not(:last-of-type)": {
      borderBottom: `${rem(1)} solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[3]
      }`,
    },
  },

  searchCode: {
    fontWeight: 700,
    fontSize: rem(10),
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[2]
    }`,
  },

  mainLinks: {
    paddingLeft: `calc(${theme.spacing.md} - ${theme.spacing.xs})`,
    paddingRight: `calc(${theme.spacing.md} - ${theme.spacing.xs})`,
    paddingBottom: theme.spacing.md,
  },

  mainLink: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    fontSize: theme.fontSizes.xs,
    padding: `${rem(8)} ${theme.spacing.xs}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  mainLinkInner: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },

  mainLinkIcon: {
    marginRight: theme.spacing.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
  },

  mainLinkBadge: {
    padding: 0,
    width: rem(20),
    height: rem(20),
    pointerEvents: "none",
  },

  channels: {
    paddingLeft: `calc(${theme.spacing.md} - ${rem(6)})`,
    paddingRight: `calc(${theme.spacing.md} - ${rem(6)})`,
    paddingBottom: theme.spacing.md,
  },

  channelHeader: {
    paddingLeft: `calc(${theme.spacing.md} + ${rem(2)})`,
    paddingRight: theme.spacing.md,
    marginBottom: rem(5),
  },

  channelLink: {
    display: "block",
    padding: `${rem(8)} ${theme.spacing.xs}`,
    textDecoration: "none",
    borderRadius: theme.radius.sm,
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    lineHeight: 1,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  channelActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },

  user: {
    display: "block",
    width: "100%",
    padding: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

    // "&:hover": {
    //   backgroundColor:
    //     theme.colorScheme === "dark"
    //       ? theme.colors.dark[8]
    //       : theme.colors.gray[0],
    // },
  },
}));

export const apps = [
  { icon: ICONS.notebook, label: "가상 노트북", value: "notebook" },
  { icon: ICONS.aicomment, label: "ChatGPT", value: "chatgpt" },
  { icon: ICONS.gptDocs, label: "GPT Docs", value: "gptdocs" },
  { icon: ICONS.designGPT, label: "Design GPT", value: "designgpt" },
  { icon: ICONS.GPTCoder, label: "GPT Coder", value: "gptcoder" },
  // { icon: IconUser, label: "Contacts", notifications: 4 },
];

export interface GroupLeftSideBarProps {}
export default function GroupLeftSideBar({}: GroupLeftSideBarProps) {
  const { data: session } = useSession();
  const router = useRouter();
  const { groupUid, channelUid, appUid } = router.query as {
    groupUid: string;
    channelUid: string;
    appUid: string;
  };

  const { data } = useGetGroupByUid(
    { uid: groupUid },
    { enabled: !!session?.sessionToken && !!groupUid }
  );

  const { data: userData } = useGetGroupListByUid(
    { uid: groupUid },
    { enabled: !!session?.sessionToken && !!groupUid && !!data }
  );

  const isGroupAdmin =
    userData?.hits.find((item) => item.user.id === session?.id)?.save_level ===
    99;

  const { classes, cx } = useStyles();

  const appLinks = apps.map((item) => {
    const isActive = appUid === item.value;
    return (
      <button
        key={item.label}
        className={`${classes.mainLink} ${
          isActive ? "menu-card-active" : "menu-card"
        }`}
        onClick={() => router.push(`/groups/${groupUid}/apps/${item.value}`)}
      >
        <div className={`${classes.mainLinkInner} w-full`}>
          <item.icon size={20} className={classes.mainLinkIcon} stroke={1.5} />
          <span>{item.label}</span>
        </div>
        {/* {link.notifications && (
        <Badge size="sm" variant="filled" className={classes.mainLinkBadge}>
          {link.notifications}
        </Badge>
      )} */}
      </button>
    );
  });

  const channelLinks = data?.channel?.map((item) => {
    const isActive = channelUid === item.channel_uid;
    return (
      <Link
        href={`/groups/${groupUid}/channels/${item.channel_uid}`}
        key={item.channel_uid}
        className={`text-sm ${isActive ? "menu-card-active" : "menu-card"}`}
        // className={cx(classes.channelLink, {
        //   [classes.channelActive]: isActive,
        // })}
      >
        {item.name}
      </Link>
    );
  });
  return (
    <nav className={`fixed z-[100] hidden w-[200px] flex-none p-2 lg:block`}>
      <div className={classes.section}>
        <GroupSelector data={data} />
      </div>

      {/* <TextInput
    placeholder="검색어를 입력하세요"
    size="xs"
    icon={<IconSearch size="0.8rem" stroke={1.5} />}
    rightSectionWidth={70}
    styles={{ rightSection: { pointerEvents: "none" } }}
    mb="sm"
  /> */}

      <div className={classes.section}>
        <Link
          className={`${classes.mainLink} menu-card mx-1.5`}
          href={`/groups/${groupUid}`}
          prefetch={false}
        >
          <div className={`${classes.mainLinkInner} w-full`}>
            <ICONS.home
              size={20}
              className={classes.mainLinkIcon}
              stroke={1.5}
            />
            <span>Home</span>
          </div>
        </Link>
        <div className={classes.mainLinks}>{appLinks}</div>
      </div>

      <div className={classes.section}>
        <Group className={classes.channelHeader} position="apart">
          <Text size="xs" weight={500} color="dimmed">
            채널
          </Text>
        </Group>
        <div className={classes.channels}>{channelLinks}</div>
      </div>
      {isGroupAdmin && (
        <div className={`${classes.section} text-center`}>
          <Button onClick={() => router.push(`/groups/${groupUid}/setting`)}>
            <ICONS.setting size={"20"} stroke={1.5} />
            그룹 설정
          </Button>
        </div>
      )}
    </nav>
  );
}
