import { Session } from "next-auth";
import { signOut } from "next-auth/react";
import { useRouter } from "next/router";

import { Avatar, AvatarProps, Menu } from "@mantine/core";
import {
  IconArticle,
  IconBookmark,
  IconLink,
  IconLogout,
  IconMessageCircle2,
  IconPackages,
  IconSettings,
} from "@tabler/icons-react";

import MenuLabelWithIcon from "components/common/MenuLabelWithIcon";
import ActionIcon from "components/common/button/ActionIcon";
import { MenuType } from "types/types";

export const AVATAR_MENUS: MenuType[] = [
  {
    label: "프로필 설정",
    value: "profile",
    icon: IconSettings,
  },
  {
    label: "내 콘텐츠",
    value: "contents",
    icon: IconLink,
  },
  {
    label: "내 컬렉션",
    value: "collections",
    icon: IconPackages,
  },
  {
    label: "내 스크랩",
    value: "scraps",
    icon: IconBookmark,
  },
  {
    label: "내 의견",
    value: "comments",
    icon: IconMessageCircle2,
  },
  {
    label: "로그아웃",
    value: "logout",
    icon: IconLogout,
  },
];

interface AvatarMenuProps {
  session: Session;
  avatarProps?: AvatarProps;
}

export default function AvatarMenu({ session, avatarProps }: AvatarMenuProps) {
  const router = useRouter();

  const handleSignOut = () => {
    localStorage.removeItem("groupIndex");
    signOut({ callbackUrl: `/` });
  };

  const handleMenuClick = (value: MenuType["value"]) => {
    if (value === "profile") router.push(`/my/profile`);
    if (value === "contents") router.push(`/my/items?tab=contents`);
    if (value === "collections") router.push(`/my/items?tab=collections`);
    if (value === "scraps") router.push(`/my/items?tab=scraps`);
    if (value === "comments") router.push(`/my/items?tab=comments`);
    if (value === "logout") handleSignOut();
  };

  if (!session) return <></>;
  return (
    <Menu withArrow offset={14}>
      <Menu.Target>
        <ActionIcon eventName="AvatarMenu">
          <Avatar
            src={session.user.image}
            size={avatarProps?.size ?? 36}
            {...avatarProps}
          />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown style={{ minWidth: "125px" }}>
        {AVATAR_MENUS.map((item) => (
          <div key={item.value}>
            {item.value === "contents" && <hr />}
            {item.value === "logout" && <hr />}
            <Menu.Item onClick={() => handleMenuClick(item.value)}>
              <MenuLabelWithIcon menu={item} />
            </Menu.Item>
          </div>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
}
