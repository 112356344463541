export const QUERY_KEYS = {
  member: {
    info: ["member", "info"],
    infoAll: ["member", "info", null],
    single: (data: string) => ["member", "info", "single", data],
  },
  external: {
    list: ["external", "list"],
    my: ["external", "list", "my"],
    single: (uid: string) => ["external", "single", uid],
  },
  internal: {
    list: ["internal", "list"],
    InfinityList: ["internal", "InfinityList"],
    my: ["internal", "list", "my"],
    single: (uid: string) => ["internal", "single", uid],
    content: (uid: string) => ["internal", "single", uid, "content"],
    children: (uid: string) => ["internal", "children", uid],
    infiniteChildren: (uid: string) => ["internal", "infiniteChildren", uid],
    count: (params: { uid: string }) => ["internal", "count", params],
    flatten: (uid: string) => ["internal", "flatten", uid],
  },
  scrap: {
    list: ["scrap", "list"],
    single: (uid: string) => ["scrap", "single", uid],
    singleList: (uid: string) => ["scrap", "single", uid, "contentList"],
  },
  save: ["save"],
  group: {
    list: ["group", "list"],
    single: (uid: string) => ["group", "single", uid],
    userList: (uid: string) => ["group", "userList", uid],
  },
  comment: {
    list: (parent_uid: string) => ["comment", "list", parent_uid],
    my: ["comment", "list", "my"],
    single: (comment: string) => ["comment", "single", comment],
  },
  aicomment: {
    list: (parent_uid: string) => ["aicomment", "list", parent_uid],
    my: ["aicomment", "list", "my"],
    single: (aicomment: string) => ["aicomment", "single", aicomment],
  },
  collection: {
    list: ["collection", "list"],
    single: (uid: string) => ["collection", "single", uid],
    singleList: (uid: string) => ["collection", "single", uid, "contentList"],
  },
  upload: ["upload"],
};
