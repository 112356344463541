import Image from "next/image";
import Link from "next/link";

interface LogoProps {
  className?: string;
  size?: number;
}

export default function Logo({ className, size = 30 }: LogoProps) {
  return (
    <Link className={className} href="/" prefetch={false}>
      <Image
        src="/images/logo.png"
        width={size * 6}
        height={size}
        alt="logo"
        style={{ width: size * 6, height: size }}
        priority
      />
    </Link>
  );
}
