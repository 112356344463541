import { useRouter } from "next/router";

import { Text } from "@mantine/core";

import { GroupItem } from "types/schema";

export interface GroupSelectCardProps {
  data: GroupItem | undefined;
  onClose: () => void;
}
export default function GroupSelectCard({
  data,
  onClose,
}: GroupSelectCardProps) {
  const router = useRouter();
  const isActive = router.query.groupUid === data?.uid;

  const handleClick = () => {
    router.push(`/groups/${data?.uid}`);
    onClose();
  };

  if (!data) return null;
  return (
    <article
      style={{ flex: 1 }}
      className={`h-[49px] rounded-sm px-2 py-1 !transition-none ${
        isActive ? "menu-card-active" : "menu-card"
      }`}
      onClick={handleClick}
      title={data.title}
    >
      <Text size="sm" weight={500} truncate>
        {data.title}
      </Text>

      <Text color="dimmed" size="xs" truncate>
        {data.detail}
      </Text>
    </article>
  );
}
