import { useSession } from "next-auth/react";
import { useRouter } from "next/router";

import { Button, Loader } from "@mantine/core";

import AvatarMenu from "containers/layout/AvatarMenu";

export interface AvatarAuthButtonProps {
  className?: string;
}
export default function AvatarAuthButton({
  className = "",
}: AvatarAuthButtonProps) {
  const { data: session, status } = useSession();
  const router = useRouter();

  return (
    <div className={`${className}`}>
      {status === "authenticated" && (
        <div className="flex w-full items-center justify-end">
          <AvatarMenu session={session} />
        </div>
      )}
      {status === "loading" && <Loader variant="bars" mx={"auto"} />}
      {status === "unauthenticated" && (
        <div className="flex items-center gap-3">
          <div className="hidden lg:inline-block">
            <Button
              onClick={() => {
                router.push(`/auth/signin`);
              }}
              variant="default"
              p={10}
            >
              로그인
            </Button>
          </div>
          <Button
            onClick={() => {
              router.push(`/auth/signup`);
            }}
            p={10}
          >
            회원가입
          </Button>
        </div>
      )}
    </div>
  );
}
