import { useMemo, useState } from "react";

import { useSession } from "next-auth/react";

import {
  Divider,
  Popover,
  Text,
  TextInput,
  UnstyledButton,
  createStyles,
} from "@mantine/core";
import { IconSelector } from "@tabler/icons-react";

import { VALIDATION } from "constants/constants";
import { ICONS } from "constants/settings";
import GroupSelectCard from "containers/groups/GroupSelectCard";
import { GroupItem } from "types/schema";

import AddGroupModal from "@modals/AddGroupModal";
import { useGetGroup } from "@server/engineGroup";

const useStyles = createStyles((theme) => ({
  user: {
    display: "block",
    width: "100%",
    height: "72px",
    padding: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

    [theme.fn.smallerThan("lg")]: {
      padding: theme.spacing.xs,
    },
    [theme.fn.largerThan("lg")]: {
      padding: theme.spacing.xs,
      maxWidth: "200px",
    },

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[8]
          : theme.colors.gray[0],
    },
  },
}));

export interface GroupSelectorProps {
  data: GroupItem | undefined;
  className?: string;
}

export function GroupSelector({ data, className }: GroupSelectorProps) {
  const { classes } = useStyles();

  const { data: session } = useSession();
  const { data: groups } = useGetGroup(
    {
      _size: VALIDATION.group._size,
    },
    {
      enabled: !!session?.sessionToken,
    }
  );
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [addGroupIsOpen, setAddGroupIsOpen] = useState(false);

  const filteredGroup = useMemo(
    () =>
      groups?.filter((item) =>
        query.trim() === ""
          ? true
          : (item.title &&
              item.title.toLowerCase().includes(query.toLowerCase())) ||
            (item.detail &&
              item.detail.toLowerCase().includes(query.toLowerCase()))
      ),
    [groups, query]
  );

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Popover
        position="bottom"
        opened={isOpen}
        onChange={setIsOpen}
        offset={0}
        width="target"
      >
        <Popover.Target>
          <UnstyledButton
            className={classes.user}
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <div className="flex items-center justify-between">
              <div style={{ flex: 1 }}>
                <Text size="sm" weight={500} className="line-clamp-1">
                  {data?.title}
                </Text>

                <Text color="dimmed" size="xs" className="line-clamp-1">
                  {data?.detail}
                </Text>
              </div>
              <IconSelector size="1rem" stroke={1.5} />
            </div>
          </UnstyledButton>
        </Popover.Target>
        <Popover.Dropdown p={0}>
          <section className="p-2 lg:max-w-[200px]">
            <TextInput
              icon={<ICONS.search />}
              onChange={(event) => setQuery(event.target.value)}
              className="mb-2"
              placeholder="그룹 검색"
            />
            {filteredGroup?.map((item) => (
              <GroupSelectCard
                data={item}
                key={item.uid}
                onClose={handleClose}
              />
            ))}
            {query.trim() === "" && (
              <>
                <Divider my={2} />
                <div
                  style={{ flex: 1 }}
                  className="flex w-full items-center justify-between rounded-sm px-2 py-1 text-sm hover:cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setAddGroupIsOpen(true);
                    setIsOpen(false);
                  }}
                >
                  <Text size="sm" weight={500}>
                    그룹 생성
                  </Text>
                  <ICONS.add stroke={1.5} size={18} />
                </div>
              </>
            )}
            {filteredGroup?.length === 0 && query.trim() !== "" && (
              <Text color="dimmed" size="xs">
                검색 결과가 없어요
              </Text>
            )}
          </section>
        </Popover.Dropdown>
      </Popover>
      <AddGroupModal
        opened={addGroupIsOpen}
        onClose={() => {
          setIsOpen(false);
          setAddGroupIsOpen(false);
        }}
      />
    </>
  );
}
