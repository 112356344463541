import Link from "next/link";
import { useRouter } from "next/router";

import {
  Box,
  Center,
  Collapse,
  UnstyledButton,
  createStyles,
  rem,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";

import { apps } from "containers/groups/layout/GroupLeftSideBar";
import { GroupSelector } from "containers/groups/layout/GroupSelector";

import { useGetGroupByUid } from "@server/engineGroup";

export const useHeaderStyles = createStyles((theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    height: rem(42),
    width: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    }),
  },
  sublink: {
    display: "flex",
    alignItems: "center",
    height: rem(28),
    paddingLeft: theme.spacing.xl,
    paddingRight: theme.spacing.xl,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    // fontWeight: 400,
    fontSize: theme.fontSizes.sm,

    sublinkActive: {
      "&, &:hover": {
        backgroundColor: theme.fn.variant({
          variant: "light",
          color: theme.primaryColor,
        }).background,
        color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
          .color,
      },
    },

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    }),
  },
}));

export interface GroupHeaderProps {
  handleClose?: () => void;
}
export default function GroupHeader({ handleClose }: GroupHeaderProps) {
  const router = useRouter();
  const { groupUid, channelUid, appUid } = router.query as {
    groupUid: string;
    channelUid: string;
    appUid: string;
  };

  const { classes, theme } = useHeaderStyles();

  const { data } = useGetGroupByUid(
    {
      uid: groupUid,
    },
    {
      enabled: !!groupUid,
    }
  );

  const [channelsOpened, { toggle: toggleChannels }] = useDisclosure(true);
  const [appsOpened, { toggle: toggleApps }] = useDisclosure(true);

  const channelLinks = data?.channel?.map((item) => {
    const isActive = channelUid === item.channel_uid;
    return (
      <Link
        href={`/groups/${groupUid}/channels/${item.channel_uid}`}
        key={item.channel_uid}
        onClick={handleClose}
        className={`${classes.sublink} ${
          isActive ? "menu-card-active" : "menu-card"
        } rounded-sm`}
      >
        {item.name}
      </Link>
    );
  });

  const appLinks = apps.map((item) => {
    const isActive = appUid === item.value;
    return (
      <Link
        href={`/groups/${groupUid}/apps/${item.value}`}
        key={item.label}
        onClick={handleClose}
        className={`${classes.sublink} ${
          isActive ? "menu-card-active" : "menu-card"
        } rounded-sm`}
      >
        {item.label}
      </Link>
    );
  });

  return (
    <>
      <div className="mb-2 rounded-sm border">
        <GroupSelector data={data} />
      </div>
      <div className="grid grid-cols-2">
        <div>
          <UnstyledButton className={classes.link} onClick={toggleChannels}>
            <Center inline>
              <Box component="span" mr={5}>
                그룹 채널
              </Box>
              <IconChevronDown
                size={16}
                color={theme.fn.primaryColor()}
                className={`transition ${channelsOpened && "rotate-180"}`}
              />
            </Center>
          </UnstyledButton>
          <Collapse in={channelsOpened}>{channelLinks}</Collapse>
        </div>
        <div>
          <UnstyledButton className={classes.link} onClick={toggleApps}>
            <Center inline>
              <Box component="span" mr={5}>
                그룹 앱
              </Box>
              <IconChevronDown
                size={16}
                color={theme.fn.primaryColor()}
                className={`transition ${appsOpened && "rotate-180"}`}
              />
            </Center>
          </UnstyledButton>
          <Collapse in={appsOpened}>{appLinks}</Collapse>
        </div>
      </div>
    </>
  );
}
