export const BlurData =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkCF1YDwACygF3cG03ZAAAAABJRU5ErkJggg==";

export const ISR = {
  revalidate: 10,
  size: process.env.NEXT_PUBLIC_DEPLOY_ENV === "production" ? 500 : 5,
};

export const ELASTIC_DELAY = 1000;

export const HEIGHT = {
  content: 400,
  collection: 200,
  comment: 106,
  my: 106,
};

export const CARD_SIZE = {
  max_w: 360,
  max_h: 342,
  max_h_image: 200,
  image_aspect: 1.6,
};

export const DEFAULT_INTERNAL_CHILDREN_FETCH_SIZE = 10;
export const DEFAULT_INTERNAL_COURSES_FETCH_SIZE = 15;
export const DEFAULT_INTERNAL_MARKETS_FETCH_SIZE = 15;

export const VALIDATION = {
  title: {
    maxLength: 100,
  },
  community: {
    contents: {
      _size: 20,
    },
  },
  group: {
    _size: 100,
    title: { maxLength: 30 },
    detail: { maxLength: 100 },
    contents: {
      _size: 20,
    },
  },
  search: {
    maxLength: 50,
  },
  icon: {
    buttonIconSize: 20,
    buttonIconStroke: 1.5,
  },
  chatgpt: {
    _size: 200,
  },
};

export const Z_INDEX = {
  modal: 201,
  topNav: 200,
  groupSideNav: 100,
};

export const HTML_ID = {
  header: "header",
  comment: "anchor-comment",
  commentForm: "anchor-comment-form",
};
