import { useEffect } from "react";

import { useSession } from "next-auth/react";
import { useRouter } from "next/router";

import { Flex } from "@mantine/core";
import { setUser } from "@sentry/browser";
import { useSetAtom } from "jotai";

import Footer from "containers/layout/Footer";
import Header from "containers/layout/Header";

import { atomPreviousPath } from "@client/stateAtoms";
import instance from "@server/axiosInstance";

export interface LayoutProps {
  children: JSX.Element;
}

export default function Layout({ children }: LayoutProps) {
  const { data: session } = useSession();
  const token = session?.sessionToken;
  instance.defaults.headers.common["token"] = token;

  const setPreviousPath = useSetAtom(atomPreviousPath);
  const { asPath } = useRouter();

  useEffect(() => {
    setPreviousPath(asPath);
  }, [asPath]);

  useEffect(() => {
    if (session) {
      const userObj = {
        id: session.id,
        name: session.user.name ?? "UNDEFINED",
        email: session.user.email ?? "UNDEFINED",
      };

      setUser(userObj);
    }
  }, [session]);

  return (
    <>
      <Header />
      <div className="mx-auto mt-[50px] h-full max-w-[1320px] px-[10px] lg:mt-[60px] lg:px-[20px]">
        {children}
      </div>
    </>
  );
}
