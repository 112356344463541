import { useSession } from "next-auth/react";
import { useRouter } from "next/router";

import { Group, Text, ThemeIcon, createStyles, rem } from "@mantine/core";
import { Icon } from "@tabler/icons-react";

import { openModals } from "@client/modals";

export const useHeaderStyles = createStyles((theme) => ({
  subLink: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
    }),

    "&:active": theme.activeStyles,
  },
}));

export interface HeaderMenuProps {
  menus: {
    title: string;
    description: string;
    icon: Icon;
    href: string;
    needLogin?: boolean;
  }[];
  onClick: () => void;
}

export default function HeaderMenu({ menus, onClick }: HeaderMenuProps) {
  const { classes, theme } = useHeaderStyles();
  const router = useRouter();
  const { data: session } = useSession();

  return (
    <>
      {menus.map((item) => (
        <div
          className={`${classes.subLink} cursor-pointer`}
          key={item.title}
          onClick={() => {
            onClick();
            if ((item.href.startsWith("/apps") || item.needLogin) && !session) {
              return openModals.needLogin({});
            }
            router.push(item.href);
          }}
        >
          <Group noWrap align="flex-start">
            <ThemeIcon size={34} variant="default" radius="md">
              <item.icon size={rem(22)} color={theme.fn.primaryColor()} />
            </ThemeIcon>
            <div>
              <Text size="sm" fw={500}>
                {item.title}
              </Text>
              <Text size="xs" color="dimmed">
                {item.description}
              </Text>
            </div>
          </Group>
        </div>
      ))}
    </>
  );
}
