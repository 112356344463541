import Link from "next/link";

import {
  Box,
  Burger,
  Group,
  HeaderProps,
  Header as MantineHeader,
  MediaQuery,
  createStyles,
  rem,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { HTML_ID, Z_INDEX } from "constants/constants";
import { APPS, BOARD_MENUS } from "constants/menus";
import AvatarAuthButton from "containers/layout/AvatarAuthButton";
import HeaderApps from "containers/layout/HeaderApps";
import HeaderBoards from "containers/layout/HeaderBoards";
import HeaderMenu from "containers/layout/HeaderMenu";
import Logo from "containers/layout/Logo";
import NavDrawer from "containers/layout/NavDrawer";

export const useHeaderStyles = createStyles((theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan("lg")]: {
      height: rem(42),
      display: "flex",
      alignItems: "center",
      width: "100%",
    },

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    }),
  },

  subLink: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
    }),

    "&:active": theme.activeStyles,
  },

  dropdownFooter: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    margin: `calc(${theme.spacing.md} * -1)`,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
    paddingBottom: theme.spacing.xl,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  hiddenMobile: {
    [theme.fn.smallerThan("lg")]: {
      display: "none",
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan("lg")]: {
      display: "none",
    },
  },
}));

export default function Header() {
  const [drawerIsOpen, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [linksIsOpen, { open: openLinks, close: closeLinks }] =
    useDisclosure(false);
  const [appsIsOpen, { open: openApps, close: closeApps }] =
    useDisclosure(false);
  const { classes, theme } = useHeaderStyles();

  const ResponsiveHeader = ({
    children,
    ...props
  }: Omit<HeaderProps, "height" | "fixed">) => {
    return (
      <>
        <MediaQuery largerThan="lg" styles={{ display: "none" }}>
          <MantineHeader
            height={50}
            fixed
            position={{ top: 0 }}
            style={{ zIndex: Z_INDEX.topNav }}
            {...props}
          >
            {children}
          </MantineHeader>
        </MediaQuery>
        <MediaQuery smallerThan="lg" styles={{ display: "none" }}>
          <MantineHeader
            height={60}
            fixed
            position={{ top: 0 }}
            style={{ zIndex: Z_INDEX.topNav }}
            {...props}
          >
            {children}
          </MantineHeader>
        </MediaQuery>
      </>
    );
  };

  return (
    <Box pb={10} id={HTML_ID.header}>
      <ResponsiveHeader px="md">
        <Group position="apart" sx={{ height: "100%" }} maw={1280} mx={"auto"}>
          <div className="flex items-center gap-3.5 lg:hidden">
            <Burger opened={drawerIsOpen} onClick={toggleDrawer} size={24} />

            <Logo size={25} />
          </div>

          <Logo size={30} className="hidden lg:block" />
          <Group
            sx={{ height: "100%" }}
            spacing={0}
            className={classes.hiddenMobile + " space-x-1"}
          >
            <HeaderBoards
              links={
                <HeaderMenu
                  menus={BOARD_MENUS}
                  onClick={() => {
                    closeLinks();
                    closeDrawer();
                  }}
                />
              }
              isOpen={linksIsOpen}
              handleOpen={openLinks}
              handleClose={closeLinks}
            />
            <Link prefetch={false} href="/courses" className={classes.link}>
              Course
            </Link>
            <HeaderApps
              links={
                <HeaderMenu
                  menus={APPS}
                  onClick={() => {
                    closeApps();
                    closeDrawer();
                  }}
                />
              }
              isOpen={appsIsOpen}
              handleOpen={openApps}
              handleClose={closeApps}
            />
            <Link
              prefetch={false}
              href={`/groups`}
              className={`${classes.link}`}
            >
              워크스페이스
            </Link>
          </Group>

          <AvatarAuthButton className="lg:w-[180px]" />
        </Group>
      </ResponsiveHeader>

      <NavDrawer isOpen={drawerIsOpen} handleClose={closeDrawer} />
    </Box>
  );
}
