import { forwardRef } from "react";

import {
  Button as MantineButton,
  ButtonProps as MantineButtonProps,
  Tooltip,
} from "@mantine/core";

import { CDTButtonProps } from "types/components";

import { userAnalysis } from "@client/userAnalysis";

interface ButtonProps extends MantineButtonProps, CDTButtonProps {
  id?: string;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      onClick,
      eventName,
      eventProps,
      tooltip,
      tooltipProps,
      ...buttonProps
    }: ButtonProps,
    ref
  ) => {
    const handleClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      userAnalysis(eventName, "BtnClick");
      onClick && onClick(event);
    };

    return (
      <Tooltip label={tooltip} hidden={!tooltip}>
        <MantineButton ref={ref} onClick={handleClick} {...buttonProps}>
          {children}
        </MantineButton>
      </Tooltip>
    );
  }
);

Button.displayName = "Button";

export default Button;
