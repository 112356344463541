import ReactGA from "react-ga4";

export type UserAnalysisType =
  | "BtnClick"
  | "CardClick"
  | "Error-ReactQuery"
  | "Unauthorized"
  | "Error-Boundary";

export const userAnalysis = (
  eventName: string,
  category: UserAnalysisType,
  props?: any
) => {
  ReactGA.event({
    category: category,
    action: eventName,
  });
};
