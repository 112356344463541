import { useEffect } from "react";

import { NextPage } from "next";
import { SessionProvider } from "next-auth/react";
import { DefaultSeo } from "next-seo";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import Script from "next/script";

import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
// import { Noto_Sans_KR } from "@next/font/google";
import { Hydrate, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "dayjs/locale/ko";
// import "prismjs/themes/prism.css";
import "prismjs/themes/prism-tomorrow.css";
import ReactGA from "react-ga4";
import "react-toastify/dist/ReactToastify.css";

import { ErrorBoundary } from "containers/common/ErrorBoundary";
import ErrorPage from "containers/common/ErrorPage";
import Layout from "containers/layout/Layout";
import "styles/globals.css";

// import "github-markdown-css";
import { RouterTransition } from "@external/RouterTransition";
import { emotionCache, mantineTheme } from "@external/mantine";
import { nextSeoConfig } from "@external/next-seo.config";
import { queryClient } from "@server/queryClient";

const ToastContainer = dynamic(() =>
  import("react-toastify").then((mod) => mod.ToastContainer)
);

// const noto = Noto_Sans_KR({
//   weight: ["100", "300", "400", "500", "700", "900"],
//   subsets: ["korean", "latin"],
//   style: ["normal"],
// });

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactElement;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout & { auth?: boolean };
};

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) {
  const router = useRouter();

  const getLayout = Component.getLayout || ((page) => <Layout>{page}</Layout>);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize("G-RNR324W3NS");
    }

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/sw.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    }
  }, []);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
      });
    }
  }, [router]);

  return (
    <SessionProvider session={session}>
      <QueryClientProvider client={queryClient}>
        <MantineProvider
          theme={mantineTheme}
          withNormalizeCSS
          withGlobalStyles
          emotionCache={emotionCache}
        >
          <ModalsProvider labels={{ confirm: "확인", cancel: "취소" }}>
            <ErrorBoundary fallback={<ErrorPage />}>
              <RouterTransition />
              <Hydrate state={pageProps.dehydratedState}>
                <DefaultSeo {...nextSeoConfig} />
                {getLayout(<Component {...pageProps} />)}
              </Hydrate>
            </ErrorBoundary>
            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick={false}
              rtl={false}
              draggable={false}
              pauseOnFocusLoss={false}
              pauseOnHover={true}
            />
          </ModalsProvider>
        </MantineProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      <Script
        async
        onError={(e) => {
          console.error("Script failed to load", e);
        }}
        strategy="afterInteractive"
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8015781611019902"
        crossOrigin="anonymous"
      />
    </SessionProvider>
  );
}
