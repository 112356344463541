import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { omit } from "lodash-es";
import { toast } from "react-toastify";

import { QUERY_KEYS } from "constants/queryKeys";
import {
  DeleteResponses,
  EngineBase,
  GetListParams,
  GetListResponses,
  GetSingleParams,
  GroupItem,
  PatchResponses,
  PostResponses,
  SaveItem,
  UidSingleParams,
} from "types/schema";
import { EngineError } from "types/types";

import instance from "@server/axiosInstance";
import { queryClient } from "@server/queryClient";

// [POST] /group/
export type PostGroupParams = {
  body: Partial<Omit<GroupItem, keyof EngineBase>> & {
    title: GroupItem["title"];
    detail: GroupItem["detail"];
  };
};
export const postGroup = async ({ body }: PostGroupParams) => {
  const { data } = await instance({
    url: `/group/`,
    method: "post",
    data: { ...body },
  });
  return data;
};
export const usePostGroup = (
  options?: UseMutationOptions<
    PostResponses,
    AxiosError<EngineError>,
    PostGroupParams
  >
) => {
  return useMutation<PostResponses, AxiosError<EngineError>, PostGroupParams>(
    (params) => postGroup(params),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.group.list,
          refetchType: "all",
        });
        toast.success("그룹을 생성했어요.");
        options?.onSuccess && options.onSuccess(data, variables, context);
      },
      onError: (error, variables, context) => {
        console.log(error.response?.data.detail);
        toast.error("그룹 생성을 실패했어요.");
        options?.onError && options.onError(error, variables, context);
      },
      ...omit(options, ["onSuccess", "onError"]),
    }
  );
};

// [PATCH] /group/{uid}
export type PatchGroupByUidParams = {
  body: Partial<Omit<GroupItem, keyof EngineBase>>;
} & UidSingleParams;
export const patchGroupByUid = async ({ uid, body }: PatchGroupByUidParams) => {
  const { data } = await instance({
    url: `/group/${uid}`,
    method: "patch",
    data: { ...body },
  });
  return data;
};
export const usePatchGroupByUid = (
  options?: UseMutationOptions<
    PatchResponses,
    AxiosError<EngineError>,
    PatchGroupByUidParams
  >
) => {
  return useMutation<
    PatchResponses,
    AxiosError<EngineError>,
    PatchGroupByUidParams
  >((params) => patchGroupByUid(params), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.group.list,
        refetchType: "all",
      });
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.group.single(variables.uid),
        refetchType: "all",
      });
      toast.success("그룹을 업데이트 했어요.");
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      console.log(error.response?.data.detail);
      toast.error("그룹 업데이트를 실패했어요.");
      options?.onError && options.onError(error, variables, context);
    },
    ...omit(options, ["onSuccess", "onError"]),
  });
};

// [DELETE] /group/{uid}
export const deleteGroupByUid = async ({ uid }: UidSingleParams) => {
  const { data } = await instance({
    url: `/group/${uid}`,
    method: "delete",
  });
  return data;
};
export const useDeleteGroupByUid = (
  options?: UseMutationOptions<
    DeleteResponses,
    AxiosError<EngineError>,
    UidSingleParams
  >
) => {
  return useMutation<DeleteResponses, AxiosError<EngineError>, UidSingleParams>(
    (params) => deleteGroupByUid(params),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.group.list,
          refetchType: "all",
        });
        toast.success("그룹을 삭제했어요.");
        options?.onSuccess && options.onSuccess(data, variables, context);
      },
      onError: (error, variables, context) => {
        console.log(error.response?.data.detail);
        toast.error("그룹 삭제를 실패했어요.");
        options?.onError && options.onError(error, variables, context);
      },
      ...omit(options, ["onSuccess", "onError"]),
    }
  );
};

// [GET] /group/{uid}
export const getGroupByUid = async ({
  uid,
  projection,
}: GetSingleParams): Promise<GroupItem> => {
  const { data } = await instance({
    url: `/group/${uid}`,
    method: "get",
    params: {
      projection,
    },
  });
  return data;
};
export const useGetGroupByUid = (
  params: GetSingleParams,
  options?: UseQueryOptions<GroupItem, AxiosError<EngineError>>
) => {
  return useQuery<GroupItem, AxiosError<EngineError>>(
    [...QUERY_KEYS.group.single(params.uid), omit(params, params.uid)],
    () => getGroupByUid(params),
    {
      ...options,
    }
  );
};

// [GET] /group/
export const getGroup = async ({
  keyword,
  _size,
  _from,
  sort,
  direction,
  _source,
  query,
}: GetListParams): Promise<GroupItem[]> => {
  const { data } = await instance({
    url: `/group/`,
    method: "get",
    params: {
      keyword,
      _size,
      _from,
      sort,
      direction,
      _source,
    },
    data: query,
  });
  return data;
};
export const useGetGroup = (
  params: GetListParams,
  options?: UseQueryOptions<GroupItem[], AxiosError<EngineError>>
) => {
  return useQuery<GroupItem[], AxiosError<EngineError>>(
    [...QUERY_KEYS.group.list, params],
    () => getGroup(params),
    {
      ...options,
    }
  );
};

export interface GetGroupListByUidParams extends GetListParams {
  uid: string;
}
export interface GetGroupListByUidResponses
  extends GetListResponses<SaveItem> {}
// [GET] /group/list/{group_uid}
export const getGroupListByUid = async ({
  uid,
  keyword,
  _size,
  _from,
  sort,
  direction,
  _source,
}: GetGroupListByUidParams): Promise<GetGroupListByUidResponses> => {
  const { data } = await instance({
    url: `/group/list/${uid}`,
    method: "get",
    params: {
      keyword,
      _size,
      _from,
      sort,
      direction,
      _source,
    },
  });
  return data;
};
export const useGetGroupListByUid = (
  params: GetGroupListByUidParams,
  options?: UseQueryOptions<GetGroupListByUidResponses, AxiosError<EngineError>>
) => {
  return useQuery<GetGroupListByUidResponses, AxiosError<EngineError>>(
    [...QUERY_KEYS.group.userList(params.uid)],
    () => getGroupListByUid(params),
    {
      ...options,
    }
  );
};
