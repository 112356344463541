import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

import {
  Box,
  Center,
  Collapse,
  Divider,
  Drawer,
  UnstyledButton,
  createStyles,
  rem,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";

import { APPS, BOARD_MENUS } from "constants/menus";
import GroupHeader from "containers/groups/layout/GroupHeader";

export const useHeaderStyles = createStyles((theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    height: rem(42),
    width: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    }),
  },
  sublink: {
    display: "flex",
    alignItems: "center",
    height: rem(28),
    paddingLeft: theme.spacing.xl,
    paddingRight: theme.spacing.xl,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    // fontWeight: 400,
    fontSize: theme.fontSizes.sm,

    sublinkActive: {
      "&, &:hover": {
        backgroundColor: theme.fn.variant({
          variant: "light",
          color: theme.primaryColor,
        }).background,
        color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
          .color,
      },
    },

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    }),
  },
}));

export interface NavDrawerProps {
  isOpen: boolean;
  handleClose: () => void;
}

export default function NavDrawer({ isOpen, handleClose }: NavDrawerProps) {
  const LogoHeight = 25;
  const router = useRouter();
  const { groupUid } = router.query as {
    groupUid: string;
  };
  const { classes, theme } = useHeaderStyles();
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(true);
  const [appsOpened, { toggle: toggleApps }] = useDisclosure(true);

  return (
    <Drawer
      opened={isOpen}
      onClose={handleClose}
      size="100%"
      title={
        <Image
          src="/images/logo.png"
          width={LogoHeight * 6}
          height={LogoHeight}
          alt="logo"
          style={{ width: LogoHeight * 6, height: LogoHeight }}
          priority
        />
      }
      className={"lg:hidden"}
      zIndex={1000000}
      styles={{
        header: {
          paddingBottom: 2,
        },
      }}
    >
      <Divider
        my="sm"
        color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
      />
      {groupUid && (
        <div className="lg:hidden">
          <GroupHeader handleClose={handleClose} />
          <Divider
            my="sm"
            color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
          />
        </div>
      )}
      <div className="grid grid-cols-2">
        <div>
          <UnstyledButton className={classes.link} onClick={toggleLinks}>
            <Center inline>
              <Box component="span" mr={5}>
                공개 커뮤니티
              </Box>
              <IconChevronDown
                size={16}
                color={theme.fn.primaryColor()}
                className={`transition ${linksOpened && "rotate-180"}`}
              />
            </Center>
          </UnstyledButton>
          <Collapse in={linksOpened}>
            {BOARD_MENUS.map((item) => {
              const isActive = router.asPath.startsWith(item.href);
              return (
                <Link
                  key={item.title}
                  href={item.href}
                  onClick={handleClose}
                  className={`${classes.sublink} ${
                    isActive ? "menu-card-active" : "menu-card"
                  } rounded-sm`}
                >
                  {item.title}
                </Link>
              );
            })}
          </Collapse>
        </div>
        <div>
          <UnstyledButton className={classes.link} onClick={toggleApps}>
            <Center inline>
              <Box component="span" mr={5}>
                AI앱
              </Box>
              <IconChevronDown
                size={16}
                color={theme.fn.primaryColor()}
                className={`transition ${appsOpened && "rotate-180"}`}
              />
            </Center>
          </UnstyledButton>
          <Collapse in={appsOpened}>
            {APPS.map((item) => {
              const isActive = router.asPath.startsWith(item.href);
              return (
                <Link
                  key={item.title}
                  href={item.href}
                  onClick={handleClose}
                  className={`${classes.sublink} ${
                    isActive ? "menu-card-active" : "menu-card"
                  } rounded-sm`}
                >
                  {item.title}
                </Link>
              );
            })}
          </Collapse>
        </div>
        <Link
          prefetch={false}
          href="/courses"
          className={classes.link}
          onClick={handleClose}
        >
          Course
        </Link>
        <Link
          prefetch={false}
          href={`/groups`}
          className={`${classes.link}`}
          onClick={handleClose}
        >
          워크스페이스
        </Link>
      </div>

      <Divider
        my="sm"
        color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
      />
    </Drawer>
  );
}
